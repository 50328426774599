<template>
  <div class="area wapper" v-if="pageLoadingComplete">
    <commonHeader />
    <div class="container">
      <div class="area__header">
        <div class="area__title">
          <p class="text">当前选择的地区</p>
          <p class="button" v-model="currentProvince.name"><img :src="require('@/assets/images/logistics/local.png')"
              class="icon">
            {{ currentProvince.name }}</p>
        </div>
      </div>
      <div class="area__content">
        <div class="area__list">
          <van-grid :column-num="3" :border="false">
            <van-grid-item v-for="(item,index) in province_list" :key="index">
              <van-button type="info" color="#F7F8FA" @click="changeProvince(item.key)">{{ item.name }}</van-button>
            </van-grid-item>
          </van-grid>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {getProvinceInfor} from '@/api/user'
import commonMixins from "@/mixins/common";

export default {
  mixins: [commonMixins],
  data() {
    return {
      province: '',
      province_list: {},
      pageLoadingComplete: false,
    }
  },
  computed: {
    currentProvince() {
      return this.province_list[this.province] || ''
    }
  },
  mounted() {
    this.$store.dispatch('getProvinceKey').then((province) => {
      this.province = province
    })
    getProvinceInfor().then((res) => {
      this.province_list = res?.data.province_list
      this.pageLoadingComplete = true
    })
   this.$util.setShare()
  },
  methods: {
    changeProvince(key) {
      let nameList = ['goods', 'order', 'address', 'user', 'payment', 'search', 'select']
      let flag = true
      nameList.map(item => {
        if (document.referrer.indexOf(item) >= 0) {
          flag = false
        }
      })
      this.$store.dispatch('setProvinceKey', key).then((res) => {
        this.$router.go(-1)
      })
    }
  },
}
</script>

<style scoped lang='scss'>
.wapper {
  background-color: #fff;
}

.area {
  &__title {
    @include flex-align_center;
    margin-bottom: .55rem;

    .text {
      font-size: 0.4rem;
      color: #18191A;
      margin-right: .37rem;
    }

    .button {
      @include flex_center;
      background-color: rgba(#FF6A4D, .2);
      border: 1px solid #FF6A4D;
      border-radius: .2rem;
      width: 2.2rem;
      height: 0.9rem;
      font-size: 0.4rem;
      color: #18191A;

      .icon {
        width: 0.4rem;
        margin-right: .1rem;
      }
    }
  }

  &__list {
    margin: 0 -.21rem;

    ::v-deep .van-grid-item__content {
      padding: .16rem .21rem;
    }

    .van-button {
      width: 100%;
      border-radius: .2rem;

      .van-button__text {
        font-size: 0.4rem;
        color: #18191A;
      }
    }
  }

}
</style>
